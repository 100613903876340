var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-tabs", attrs: { id: "vertical-tabs" } },
    [
      _vm.tabs && _vm.tabs.length && (!!_vm.tabIndex || _vm.tabIndex == 0)
        ? _c(
            "vs-tabs",
            {
              attrs: { color: _vm.tabColor },
              model: {
                value: _vm.tabIndex,
                callback: function ($$v) {
                  _vm.tabIndex = $$v
                },
                expression: "tabIndex",
              },
            },
            _vm._l(_vm.tabs, function (item, index) {
              return _c(
                "vs-tab",
                {
                  key: `tab-${item.tab}`,
                  attrs: {
                    value: index,
                    label: item.label,
                    disabled: item.disabled,
                  },
                },
                [
                  _vm.isManyContents
                    ? _vm._t(`tab-content-${item.tab}`)
                    : _vm.isRouterContent
                    ? _c("router-view")
                    : _vm._t("default"),
                ],
                2
              )
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }