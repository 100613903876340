<template>
  <div class="new-tabs" id="vertical-tabs">
    <vs-tabs
      :color="tabColor"
      v-model="tabIndex"
      v-if="tabs && tabs.length && (!!tabIndex || tabIndex == 0)"
    >
      <vs-tab
        :value="index"
        :label="item.label"
        v-for="(item, index) of tabs"
        :key="`tab-${item.tab}`"
        :disabled="item.disabled"
      >
        <slot :name="`tab-content-${item.tab}`" v-if="isManyContents"></slot>
        <router-view v-else-if="isRouterContent"></router-view>
        <slot v-else></slot>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
export default {
  props: {
    tabs: { type: Array, require: true },
    tabColor: { type: String, default: "primary" },
    isManyContents: { type: Boolean, default: true },
    isRouterContent: { type: Boolean, default: false }
  },
  data() {
    return {
      tabIndex: null
    };
  },
  mounted() {
    this.tabIndex = 0;
  },
  watch: {
    tabIndex(value) {
      if (this.isRouterContent) {
        if (this.tabs[value].router.params) {
          this.$router.push({
            name: this.tabs[value].router.name,
            params: {
              [this.tabs[value].router.params.name]: this.tabs[value].router
                .params.value
            }
          });
        } else this.$router.push({ name: this.tabs[value].router.name });
        return;
      }

      this.$emit("set-tab", { content: this.tabs[value], index: value });
    }
  }
};
</script>

<style lang="scss">
#vertical-tabs {
  .con-vs-tabs {
    display: flex;
  }

  .con-ul-tabs {
    background: white;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 270px;
    margin-bottom: 10px;
    padding: 0 15px 10px;
  }

  .line-vs-tabs {
    margin-top: 0;
    width: 100% !important;
  }

  .vs-tabs--li button.vs-tabs--btn {
    margin-top: 30px;
    width: auto;
  }

  .vs-tabs--ul {
    box-shadow: none;
    flex-direction: column;
  }

  .vs-tabs--li {
    padding-right: 15px;
  }

  .con-slot-tabs {
    background: white;
    border-radius: 10px;
    margin: 0 20px 10px 5px;
    padding: 30px 20px 20px;
  }
}
</style>
