<template>
  <div class="config-modalidade new-tabs">
    <vertical-tabs-components :tabs="tabs">
      <div :slot="`tab-content-${tabs[0].tab}`">
        <div class="vx-row">
          <div class="vx-col w-1/2">
            <span class="mt-3">Modalidade <b style="color:red">*</b></span>
            <el-select
              disabled
              class="mt-3"
              v-model="modalidadeSelecionada.ModalidadeId"
              filterable
              placeholder="Escolha a modalidade"
            >
              <el-option
                v-for="item in modalidades"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="vx-col w-1/3">
            <span class="mt-3">Tipo de Índice <b style="color:red">*</b></span>
            <el-select
              class="mt-3"
              v-model="modalidadeSelecionada.IndiceId"
              filterable
              placeholder="Escolha o tipo"
            >
              <el-option
                v-for="item in indiceList"
                :key="item.Id"
                :label="item.Nome"
                :value="item.Id"
              >
              </el-option>
            </el-select>
          </div>
          <div class="vx-col w-full" style="margin-top: 50px">
            <div class="card-gray">
              <div class="left">
                <span class="pl-3 bold">
                  Status da modalidade
                </span>
              </div>
              <div class="right">
                <vs-switch class="mr-3" v-model="modalidadeSelecionada.Ativo" />
                <label for="" style="font-size: 13px; margin-right: 12px;">
                  {{
                    modalidadeSelecionada.Ativo ? "Ativado" : "Desativado"
                  }}</label
                >
              </div>
            </div>
          </div>
        </div>
        <vs-row vs-type="flex" vs-justify="end" class="mt-5">
          <vs-col
            vs-type="flex"
            vs-justify="end"
            vs-align="center"
            vs-lg="3"
            vs-sm="12"
          >
            <vs-button class="button-primary text-center" @click="update()">
              Salvar Alterações
            </vs-button>
          </vs-col>
        </vs-row>
      </div>
    </vertical-tabs-components>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Breadcrumb from "@/layouts/components/breadcrumb/Breadcrumb.vue";
import VerticalTabsComponents from "@/components/vertical-tabs/VerticalTabsComponents.vue";
import instance from "@/axios";
import { Base64 } from "js-base64";
export default {
  components: {
    Breadcrumb,
    VerticalTabsComponents
  },
  data() {
    return {
      modalidadeSelecionada: null,
      indiceSelecionada: null,
      Status: false,
      modalidades: [],
      indiceList: [
        {
          NomeIndice: "IPCA",
          IndiceId: 1
        },
        {
          NomeIndice: "SELIC",
          IndiceId: 2
        }
      ]
    };
  },
  computed: {
    tabs() {
      return [
        {
          label: "Dados da Modalidade",
          tab: "Dados da Modalidade",
          disabled: false
        }
      ];
    }
  },
  mounted() {},
  created() {
    let token = Base64.decode(this.$route.params.token);
    this.modalidadeSelecionada = JSON.parse(token);
    this.ObterModalidades();
    this.ObterIndice();
  },
  methods: {
    ObterModalidades() {
      return instance({
        method: "get",
        url: "/api/Core/ObterModalidadesAtivas"
      }).then(response => {
        this.modalidades = response.data.Response;
      });
    },
    ObterIndice() {
      return instance({
        method: "get",
        url: "/api/Parametros/ObterIndices"
      }).then(response => {
        this.indiceList = response.data.Response;
      });
    },
    update() {
      this.$vs.loading();

      let dados = {
        Id: this.modalidadeSelecionada.Id,
        ModalidadeId: this.modalidadeSelecionada.ModalidadeId,
        IndiceId: this.modalidadeSelecionada.IndiceId,
        Ativo: this.modalidadeSelecionada.Ativo
      };
      return instance({
        method: "post",
        url: "/api/Endorsement/AtualizarParamentroEndossoAniversario",
        data: dados
      })
        .then(response => {
          this.$vs.loading.close();
          this.$notify({
            title: "Success",
            message: "Dados atualizados com sucesso!",
            type: "success"
          });
          this.$router.push({
            name: "param-list-endosso"
          });
        })
        .catch(error => {
          this.$vs.loading.close();
          this.$onpoint.errorModal(error.response.data.Errors);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.branch-container {
  position: relative;
  background: #fff;
  box-shadow: 0px 3px 10px 8px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  margin-bottom: 24px;
  margin-top: 25px;

  .title {
    padding: 32px 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 138% */

    /* grayscale/gray-4 */

    color: #8e8e8e;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .modality-group-content:not(:last-of-type) {
    border-bottom: 1px solid #e4e1e1;
  }

  .modality-group-content:not(:first-of-type) {
    padding-top: 16px;
  }

  .modality-group-content {
    margin: 0 16px 16px;
    padding-bottom: 16px;

    .modality-group-title {
      justify-content: space-between;
      padding-bottom: 28px;
      padding-top: 0px;
    }

    .data-content {
      color: #50555a;
      font-size: 14px;
      font-weight: 700;
    }

    .data-title {
      color: #8e8e8e;
      font-size: 12px;
      font-weight: 700;
    }
  }
}

.card-gray {
  background: #f7f7f7;
  color: #636363;
  font-weight: bold;
  border-radius: 10px;
  border: 1px solid #63636321;
  padding: 25px 15px;
  display: flex;

  .left,
  .right {
    width: 50%;
  }

  .left {
    text-align: left;
  }

  .right {
    display: flex;
    flex-direction: row-reverse;
  }
}

.main-card {
  padding: 15px 25px;
}

.history {
  position: absolute;
  top: -65px;
  right: 0;
}
</style>
